
.about-container {
    display: flex;
    flex-wrap: wrap;
    column-gap: 1em;
}

.about-intro {
    flex-grow: 1;
    flex: 1;
    min-width: 60%;
    align-self: center;
}

.about-headshot{
    max-width: 200px;
    flex: 1;
    img{
        margin-left: auto;
        margin-right: auto;
        max-height: 350px;
    }
}

.about-content {
    margin-top: 10px;
}

@media(max-width: 720px) {
    .about-headshot {
      flex: 0 0 100%;
      order: 0;
      max-width: 100%;
    }
    .about-intro {
        flex: 0 0 100%;
    }
  }
