$dark: var(--gray-200);
$light: var(--gray-900);
$color: var(--blue-300);
$lineSize: 4px;
$buttonSize: 30px;

.hamburger-lines{
  display: block;
  height: 26px;
  width: 32px;
  position: relative;
  z-index: 2;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .line {
    display: block;
    height: 4px;
    width: 100%;
    border-radius: 10px;
    background: var(--gray-200);
  }
}

.menu-hamburger{

  &.open {

    #sidebar-menu{
      visibility: visible;
      right: 0px;
    }
    .overlay{
      opacity: 0.4;
      visibility: visible;
    }
  }
  &:hover {
    cursor: pointer;
  }
}

#sidebar-menu {
  visibility: hidden;
  position: fixed;
  top: 0;
  right: -100%;
  width: 85%;
  height: 100%;
  background-color: var(--gray-800);
  transition: 0.3s;
  box-sizing: border-box;
  padding-top: 2rem;
  z-index: 100;
  box-shadow: 0 8px 24px 0 #00000033;
  cursor: auto;
  
  .menu-item{
    font-size: 2rem;
    display: flex;
    justify-content: center;
    margin: 1.5rem 0em;
    a{
      cursor: pointer;
    }
  }
}


.menu-hamburger {
  float: right;
}

.overlay{
  transition: opacity 0.5s, visibility 0.5s;  
  visibility: hidden;
  opacity: 0;
  position: absolute;
  background-color: #000000;
  top : 0;
  left : 0;
  padding-top: 3em;
  z-index : 10;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: auto;
}

.menu-full-width{
  transition: opacity 0.5s ease-out;
}
.menu-hamburger{
  transition: opacity 0.5s ease-out;
}


@media (max-width: 30em) {
  .menu-full-width{
    opacity: 0;
    height: 0;
    overflow: hidden;
  }

  .menu-hamburger{
    opacity: 1;
    height: $buttonSize;
  }
}


@media (min-width: 30em) {
  .menu-full-width{
    opacity: 1;
    height: auto;
  }

  .menu-hamburger{
    opacity: 0;
    height: 0;
    overflow: hidden;
  }
}

