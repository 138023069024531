
.cards{    
    display: flex;
    justify-content: space-around;
    flex-flow: row wrap;
}

.cards::after {
    content: "";
    width: 280px;
}

.card:hover{
    background-color: var(--gray-700);
    box-shadow: 0 8px 24px 0 #00000033;
    width: 284px;
    height: 504px;
    .card-content{
        padding: 10px 22px;
    }
}


.card{
    margin-top: calc(var(--spacer)*1.5);
    text-decoration: none;
    border-radius: 10px;
    background-color: var(--gray-800);
    box-shadow: 0 4px 8px 0 #00000033;
    transition: 0.3s;
    width: 280px;
    height: 500px;
    display: flex;
    flex-direction: column;
}

.card-content{
    transition: 0.3s;
    flex-grow: 1;
    padding: 10px 20px;
    display: flex;
    flex-direction: column;
}

.card-image{
    object-fit: cover;
    border-radius: 10px 10px 0px 0px;
    max-height: 175px;
    overflow: hidden;
}

.card-excerpt{
    flex-grow: 1;
}

.card-footer{
    display: flex;
    margin-top: calc(var(--spacer)*0.75);
    padding-top: calc(var(--spacer)*0.75);
    padding-bottom: calc(var(--spacer)*0.75);
    border-top: 1px solid var(--gray-600);
    justify-content: flex-end;
}
.card-footer-date{
    flex-grow: 1;
}

.arrow{
    margin-top: 5px;
    width: 25px;
    height: 14px;
    font-size: 0px;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 25 14'%3E%3Cpath stroke='%23868e96' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.5' d='M1 1c0+6 3+7 10+7h13m0 0-4-4m4 4-4 4'/%3E%3C/svg%3E")
}
